import "./circular-loader.scss";

export default function CircularLoader({
  color,
  size = "small",
}: {
  color?: "blue" | "white";
  size?: "small" | "large";
}) {
  return (
    <div
      className={`circular-loader ${color === "blue" ? "circular-loader-blue" : ""} ${size === "small" ? "" : "circular-loader-large"} `}
    ></div>
  );
}
